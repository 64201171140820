import React from 'react';
import Nav from '../HomePage/Nav';
import './Cancellation.css';
import Footer from '../HomePage/Footer';

const TermsNConditions = () => {
  return (
    <>
      <div className='container'>
        <Nav />
        <div className='container text-center mt-5'>
          <div className='row'>
            <h1 style={{ marginTop: '2rem' }}>
              <b>
                {' '}
                <span style={{ color: '#111E43' }}> Terms Of Use </span>
              </b>
            </h1>
          </div>
          <div className='row mt-5'>
            <div className='col-lg-10 offset-lg-1'>
              <ul className='list-unstyled custom-font'>
                <li>
                <b>Group Cancellation by Us</b>
If the organizers need to cancel a group trip due to unexpected circumstances, we'll try to move your booking to a different date. We can't offer a refund if you don't want to switch.

                </li><br></br>
                <li>
                <b>Cancellation by You</b>
If you cancel your booking after you've signed up, there's no refund or adjustment to the booking amount. Here's how the cancellation fees work:

Before 60 days: You'll lose 30% of the package cost.
60 to 45 days before departure: 50% of the package cost is deducted.
45 days or fewer: No refund at all.
                </li><br></br>
                <li>
               <b>Changing Your Departure Date</b> 
If you want to change your trip date, you need to tell us at least 60 days in advance. We'll try to help, but it's not guaranteed.

Unutilized Services
If you don't use any services included in your package, like tours or meals, you won't receive a refund.

                </li><br></br>
                <li>
               <b>Exclusions and Extras</b> 
The price does not include anything not specifically mentioned in the package details or suggested as an optional activity.
Photos on our website are for reference; the actual places, hotels, and attractions might look different.
There will be additional charges if you want to stay longer at a destination or make extra stops.
If you need more luggage space or want to bring extra items like gifts, promotional materials, or electronics, you might need our approval, and there may be additional costs.

                </li><br></br>
                <li>
                <b>Additional Information</b>
You'll need to cover additional costs if there's a natural disaster, political issue, flight delay, or something else out of our control.
Airport transfers and coach tours are based on everyone traveling together. We can't offer extra transfers if you're on a different flight.
If a landslide, flight cancellation, or bad weather affects the itinerary, you must cover any extra costs.
Gratuities or tips are not included and are the guest's responsibility.
Any changes to the itinerary or hotel upgrades will have additional costs, which we'll inform you about in advance.
</li><br></br>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsNConditions;
