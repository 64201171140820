import React, { useState, useEffect } from "react";
import Nav from "./Nav";
import { useParams } from "react-router-dom";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import LadakhImg from "../../assets/Images/asdasdasd.jpg";
import HotelImage from "../../assets/Images/asdasdasd.jpg"; // Import Hotel Image
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

const HotelCard = () => {
  const { id } = useParams();
  const [hotels, setHotels] = useState([]);
  const [categoryHeading, setCategoryHeading] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleViewDetailClick = (slug) => {
    // console.log("Hotel ID clicked:", id);
    sessionStorage.setItem("slugg",slug);
    // navigate(`/PackageDestination/${slug}`);
    navigate(`/${slug}`);
  };

  const handleBook = (hotel) => {
    console.log("Trip clicked--:", hotel.id);
    sessionStorage.setItem("selectedId", hotel.id); // Store ID in session storage
    
    sessionStorage.setItem("slugg",hotel.slug);
    console.log("slug",hotel.slug);
    window.location.href = "/BookOnlineCarHotel";
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}AllPackages`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        //console.log("data-", data);
        const hotelsData = data.data.filter((item) => item.category_id === 1);
        //console.log("hotelsData-",hotelsData)
        setHotels(hotelsData);
        if (hotelsData.length > 0) {
          setCategoryHeading(hotelsData[0].category.heading);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const HotelCardItem = ({ hotel }) => (
    <div className="card mb-5 border shadow-sm">
      <div className="row no-gutters">
        <div className="col-md-4">
          <img
            src={hotel.image[0]}
            className="card-img"
            alt={hotel.name}
            style={{
              height: "250px",
              objectFit: "cover",
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
            }}
          />
        </div>
        <div className="col-md-8">
          <div className="card-body w-100 h-100 d-flex align-items-center">
            <div className="w-100">
              <h5 className="card-title fw-bold">{hotel.name}</h5>
              <hr />
              <p className="card-text">
                {" "}
                <FontAwesomeIcon icon={faLocationDot} className="me-1" />{" "}
                {hotel.location}
              </p>
              <p className="card-text text-primary">
                Price: Rs. <b>{hotel.price[0]}/Night (Double Sharing)</b>
                <br></br>
                <small style={{color:"red"}} >(food Included)</small>
              </p>
              <div className="text-end">
                <button
                  className="btn btn-outline-primary me-2"
                  onClick={() => handleViewDetailClick(hotel.slug)}
                >
                  View Details{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right ms-2"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                  </svg>
                </button>
                <Link
                  // to="/BookOnlineCarHotel"
                  onClick={() => handleBook(hotel)} // Corrected here
                  className="btn btn-primary"
                >
                  Book Online
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="container-fluid">
        <Nav />
        <div
          className="image-container"
          style={{
            width: "100%",
            height: "65vh",
            overflow: "hidden",
            border: "1px solid #ddd",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            position: "relative",
          }}
        >
          <img
            src={LadakhImg}
            alt="ladakhDestinations"
            className="img-fluid"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
          <div
            className="overlay-text"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "white",
              textAlign: "center",
              opacity: 0.9,
            }}
          >
            <h1 style={{ fontSize: "3rem", color: "Black" }}>
              <b>{categoryHeading}</b>
            </h1>
          </div>
        </div>

        <div className="py-5 text-center" style={{ color: "#080B13" }}>
          <h6 style={{ lineHeight: "28px" }}>
            The Best time to visit Ladakh is during the summer season from the
            month of April to July during this time Ladakh temperature is
            between 15 to 30 Degree Celsius. Ladakh is known for its extremely
            low temperatures almost all through the year. While it is quite
            pleasant in the summer time, the winters are exceedingly cold
            unforgiving. Well, in that case, naturally the best time to visit
            Ladakh is during the summers.
          </h6>
        </div>
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}
        {hotels && hotels.length > 0 && (
          <div className="row justify-content-center">
            {hotels.map((hotel, index) => (
              <div key={index} className="col-lg-7 mb-4">
                <HotelCardItem hotel={hotel} />
              </div>
            ))}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default HotelCard;
