import React from 'react'
import Footer from '../HomePage/Footer'
import Nav from '../HomePage/Nav'
import "./Cancellation.css";
const PrivacyPolicy = () => {
  return (
    <>
       <div className='container'>
        <Nav/>
        <div className='container text-center mt-5'>
          <div className='row'>
            <h1 style={{ marginTop: '2rem' }}>
              <b>
                {' '}
                <span style={{ color: '#111E43' }}> Privacy Policy</span>
              </b>
            </h1>
          </div>
          <div className='row mt-5'>
            <div className='col-lg-10 offset-lg-1'>
              <ul className='list-unstyled custom-font'>
                <li>
                If we, as the organizers, have to cancel your group trip due to unavoidable reasons, we’ll try to move your booking to another trip. If you don’t want to switch, there won’t be any refund.

                </li>
                <br></br>
                <li>
                 
If you cancel your booking after you’ve registered, you won’t get any money back or be able to transfer it to another trip.

                </li>
                <br></br>
                <li>
                Here’s the breakdown of cancellation fees:<br></br>
<ol>If you cancel more than 60 days before the trip, you’ll lose 30% of the package cost.</ol>
<ol>If you cancel between 60 and 45 days before, you’ll lose 50%.</ol>
<ol>If you cancel within 45 days of the trip, there’s no refund at all.</ol>

                </li>
                <br></br>
                <li>
                If you want to change your departure date, we can do that if you let us know at least 60 days in advance.
                </li>
                <br></br>
                <li>
                Please note that there are no refunds for any services included in your package that you don’t use. 
                </li>
                <br></br>
              </ul>
            </div>
          </div>
        </div>
        
      </div>
      <Footer/> 
    </>
  )
}

export default PrivacyPolicy