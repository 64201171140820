

// import React, { useState, useEffect, useRef } from "react";
// import LeftImage from "../../../assets/Images/a1a1.png";
// import { Link, useNavigate } from "react-router-dom";
// import "@fortawesome/free-solid-svg-icons";
// function ForgetOtp({ props }) {
//   const [otp, setOtp] = useState(["", "", "", ""]); // Array to store OTP digits
//   const [timer, setTimer] = useState(120); // Timer in seconds
//   const [disabled, setDisabled] = useState(true); // Button disabled state
//   const inputRefs = useRef([]);
//   const navigate = useNavigate(); // Get the navigate function from react-router-dom

//   useEffect(() => {
//     // Start the timer
//     const interval = setInterval(() => {
//       setTimer((prevTimer) => prevTimer - 1);
//     }, 1000);

//     // Clear the timer when it reaches 0
//     if (timer === 0) {
//       clearInterval(interval);
//       setDisabled(true); // Disable button when timer reaches 0
//     }

//     // Clean up function
//     return () => clearInterval(interval);
//   }, [timer]);

//   // Function to handle OTP input
//   const handleOtpChange = (index, value) => {
//     // Check if the entered value is a number
//     if (/^\d*$/.test(value)) {
//       const newOtp = [...otp];
//       newOtp[index] = value;
//       setOtp(newOtp);

//       // Move focus to the next input field
//       if (index < 3 && value !== "") {
//         inputRefs.current[index + 1].focus();
//       }
//     }
//   };

//   // Function to handle form submission
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     // Implement your OTP verification logic here
//     const otpCode = otp.join(""); // Join OTP digits into a single string
//     console.log("OTP submitted:", otpCode);

//     try {
//       // Call backend API to verify OTP
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}forgotPasswordVerify`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({ otp: otpCode }), // Send OTP to the backend
//         }
//       );

//       if (response.ok) {
//         // OTP verification successful, navigate to reset password page
//         navigate("/ConfirmPassword");
//       } else {
//         // OTP verification failed, handle error
//         console.error("OTP verification failed");
//         // You can display an error message to the user
//       }
//     } catch (error) {
//       console.error("Error verifying OTP:", error);
//       // Handle network errors or other exceptions
//     }
//   };

//   return (
//     <>
//       <div className="container-fluid">
//         <div className="row justify-content-center">
//           <div className="col-lg-5 p-0 d-none d-lg-block">
//           <div
//             className="w-100 h-100"
//             style={{
//               backgroundImage: `url(${LeftImage})`,
//               objectFit:"cover",
//                filter: "blur(1px)",
//               // webkitBackdropFilter: "blur(10px)",
//             }}
//           >
//             <div className="ms-3">
//               <h3 style={{ color: "#FFFFFF" }}><b>trxvllrschoice</b></h3>
//             </div>
//             <div className="h-75 d-flex justify-content-center align-items-center">
//               <h4 style={{ color: "#FFFFFF" }}><b>LADAKH</b></h4>
//             </div>
//           </div>
//           </div>
//           <div
//             className="col-lg-7 "
//             style={{ backgroundColor: "#03045E", height: "100vh" }}
//           >
//             <div className="h-100 d-flex align-items-center justify-content-center">
//               <div
//                 className="card mx-2 custom-card "
//                 style={{ maxheight: "550px", borderRadius: "20px" }}
//               >
//                 <div className="card-body p-5">
//                 <h2 className="card-title pt-3">OTP Verification</h2>
//                   <p className="text-muted pb-3" style={{ fontSize: "medium" }}>
//                     Please Verify Your OTP Send On Your Device
//                   </p>
//                   {/* Heading for OTP verification */}
//                   <form onSubmit={handleSubmit}>
//                     {/* Input fields for OTP */}
//                     <div className="mb-3 d-flex justify-content-between">
//                       {otp.map((digit, index) => (
//                         <input
//                           key={index}
//                           type="text"
//                           value={digit}
//                           maxLength="1"
//                           onChange={(e) =>
//                             handleOtpChange(index, e.target.value)
//                           }
//                           className="form-control"
//                           style={{
//                             width: "calc(20% - 10px)",
//                             textAlign: "center",
//                             marginRight: "10px",
//                             boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
//                           }} // Added box-shadow
//                           ref={(input) => (inputRefs.current[index] = input)} // Ref for focusing
//                           required
//                         />
//                       ))}
//                     </div>
//                     {/* Timer display */}
//                     <p className="text-center mb-3">
//                       {Math.floor(timer / 60)}:{timer % 60 < 10 ? "0" : ""}
//                       {timer % 60}
//                     </p>
//                     {/* Resend OTP line */}
//                     <p
//                       className="text-center mb-3"
//                       style={{ color: "#03045E" }}
//                     >
//                       Didn't Receive Code? <b>Resend</b>
//                     </p>
//                     {/* Submit button */}
//                     <div className="text-center">
//                       <button
//                         type="submit"
//                         className="btn mt-4"
//                         style={{
//                           width: "200px",
//                           borderRadius: "20px",
//                           backgroundColor: "#03045E",
//                           color: "white",
//                           fontSize: "1.2rem",
//                         }}
//                       >
//                         Submit
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default ForgetOtp;





// import React, { useState, useEffect, useRef } from "react";
// import LeftImage from "../../../assets/Images/a1a1.png";
// import { Link, useNavigate } from "react-router-dom";

// // Define the spinner component
// const Spinner = () => (
//   <div className="loader-container">
//     <div className="loader"></div>
//   </div>
// );

// function ForgetOtp({ props }) {
//   const [otp, setOtp] = useState(["", "", "", ""]); // Array to store OTP digits
//   const [timer, setTimer] = useState(120); // Timer in seconds
//   const [disabled, setDisabled] = useState(true); // Button disabled state
//   const [loading, setLoading] = useState(false); // State for loading spinner
//   const inputRefs = useRef([]);
//   const navigate = useNavigate(); // Get the navigate function from react-router-dom

//   useEffect(() => {
//     // Start the timer
//     const interval = setInterval(() => {
//       setTimer((prevTimer) => prevTimer - 1);
//     }, 1000);

//     // Clear the timer when it reaches 0
//     if (timer === 0) {
//       clearInterval(interval);
//       setDisabled(true); // Disable button when timer reaches 0
//     }

//     // Clean up function
//     return () => clearInterval(interval);
//   }, [timer]);

//   // Function to handle OTP input
//   const handleOtpChange = (index, value) => {
//     // Check if the entered value is a number
//     if (/^\d*$/.test(value)) {
//       const newOtp = [...otp];
//       newOtp[index] = value;
//       setOtp(newOtp);

//       // Move focus to the next input field
//       if (index < 3 && value !== "") {
//         inputRefs.current[index + 1].focus();
//       }
//     }
//   };

//   // Function to handle form submission
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true); // Set loading state to true when submitting the form

//     // Implement your OTP verification logic here
//     const otpCode = otp.join(""); // Join OTP digits into a single string
//     console.log("OTP submitted:", otpCode);

//     try {
//       // Call backend API to verify OTP
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}forgotPasswordVerify`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({ otp: otpCode }), // Send OTP to the backend
//         }
//       );

//       if (response.ok) {
//         // OTP verification successful, navigate to reset password page
//         navigate("/ConfirmPassword");
//       } else {
//         // OTP verification failed, handle error
//         console.error("OTP verification failed");
//         // You can display an error message to the user
//       }
//     } catch (error) {
//       console.error("Error verifying OTP:", error);
//       // Handle network errors or other exceptions
//     } finally {
//       setLoading(false); // Set loading state to false after form submission or error handling
//     }
//   };

//   return (
//     <>
//       <div className="container-fluid">
//         <div className="row justify-content-center">
//           <div className="col-lg-5 p-0 d-none d-lg-block">
//             <div
//               className="w-100 h-100"
//               style={{
//                 backgroundImage: `url(${LeftImage})`,
//                 backgroundSize: "cover", // Ensures the image covers the entire area
//                 backgroundPosition: "center", // Centers the image
//                 backgroundRepeat: "no-repeat", // Prevents tiling
//                 objectFit: "cover",
//                 filter: "blur(1px)",
//               }}
//             >
//               <div
//                 className="ms-3"
//                 style={{ cursor: "pointer", color: "#FFFFFF" }}
//                 onClick={() => {
//                   window.location.href = "/";
//                 }}
//               >
//                 <h3>
//                   <b>trxvllrschoice</b>
//                 </h3>
//               </div>
//               <div className="h-75 d-flex justify-content-center align-items-center">
//                 <h4 style={{ color: "#FFFFFF" }}>
//                   <b>LADAKH</b>
//                 </h4>
//               </div>
//             </div>
//           </div>
//           <div
//             className="col-lg-7 "
//             style={{ backgroundColor: "#03045E", height: "100vh" }}
//           >
//             <div className="h-100 d-flex align-items-center justify-content-center">
//               <div
//                 className="card mx-2 custom-card "
//                 style={{ maxHeight: "550px", borderRadius: "20px" }}
//               >
//                 <div className="card-body p-5">
//                   <h2 className="card-title pt-3">OTP Verification</h2>
//                   <p className="text-muted pb-3" style={{ fontSize: "medium" }}>
//                     Please Verify Your OTP Send On Your Device
//                   </p>
//                   {/* Heading for OTP verification */}
//                   {loading && <Spinner />} {/* Render spinner if loading state is true */}
//                   <form onSubmit={handleSubmit}>
//                     {/* Input fields for OTP */}
//                     <div className="mb-3 d-flex justify-content-between">
//                       {otp.map((digit, index) => (
//                         <input
//                           key={index}
//                           type="text"
//                           value={digit}
//                           maxLength="1"
//                           onChange={(e) =>
//                             handleOtpChange(index, e.target.value)
//                           }
//                           className="form-control"
//                           style={{
//                             width: "calc(20% - 10px)",
//                             textAlign: "center",
//                             marginRight: "10px",
//                             boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
//                           }} // Added box-shadow
//                           ref={(input) => (inputRefs.current[index] = input)} // Ref for focusing
//                           required
//                         />
//                       ))}
//                     </div>
//                     {/* Timer display */}
//                     <p className="text-center mb-3">
//                       {Math.floor(timer / 60)}:
//                       {timer % 60 < 10 ? "0" + timer % 60 : timer % 60}
//                     </p>
//                     {/* Resend OTP line */}
//                     <p
//                       className="text-center mb-3"
//                       style={{ color: "#03045E" }}
//                     >
//                       Didn't Receive Code? <b>Resend</b>
//                     </p>
//                     {/* Submit button */}
//                     <div className="text-center">
//                       <button
//                         type="submit"
//                         className="btn mt-4"
//                         style={{
//                           width: "200px",
//                           borderRadius: "20px",
//                           backgroundColor: "#03045E",
//                           color: "white",
//                           fontSize: "1.2rem",
//                         }}
//                       >
//                         Submit
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default ForgetOtp;

// import React, { useState, useEffect, useRef } from "react";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import LeftImage from "../../../assets/Images/a1a1.png";
// import { Link, useNavigate } from "react-router-dom";

// // Define the spinner component
// const Spinner = () => (
//   <div className="loader-container">
//     <div className="loader"></div>
//   </div>
// );

// function ForgetOtp({ props }) {
//   const [otp, setOtp] = useState(["", "", "", ""]); // Array to store OTP digits
//   const [timer, setTimer] = useState(120); // Timer in seconds
//   const [disabled, setDisabled] = useState(true); // Button disabled state
//   const [loading, setLoading] = useState(false); // State for loading spinner
//   const inputRefs = useRef([]);
//   const navigate = useNavigate(); // Get the navigate function from react-router-dom

//   useEffect(() => {
//     // Start the timer
//     const interval = setInterval(() => {
//       setTimer((prevTimer) => prevTimer - 1);
//     }, 1000);

//     // Clear the timer when it reaches 0
//     if (timer === 0) {
//       clearInterval(interval);
//       setDisabled(true); // Disable button when timer reaches 0
//     }

//     // Clean up function
//     return () => clearInterval(interval);
//   }, [timer]);

//   // Function to handle OTP input
//   const handleOtpChange = (index, value) => {
//     // Check if the entered value is a number
//     if (/^\d*$/.test(value)) {
//       const newOtp = [...otp];
//       newOtp[index] = value;
//       setOtp(newOtp);

//       // Move focus to the next input field
//       if (index < 3 && value !== "") {
//         inputRefs.current[index + 1].focus();
//       }
//     }
//   };

//   // Function to handle form submission
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true); // Set loading state to true when submitting the form

//     const otpCode = otp.join(""); // Join OTP digits into a single string
//     console.log("OTP submitted:", otpCode);

//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_API_URL}forgotPasswordVerify`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({ otp: otpCode }), // Send OTP to the backend
//         }
//       );

//       if (response.ok) {
//         toast.success("OTP verified successfully!");
//         navigate("/ConfirmPassword");
//       } else {
//         const errorData = await response.json();
//         toast.error(errorData.message || "OTP verification failed!");
//       }
//     } catch (error) {
//       toast.error("Error verifying OTP. Please try again.");
//       console.error("Error verifying OTP:", error);
//     } finally {
//       setLoading(false); // Set loading state to false after submission
//     }
//   };

//   return (
//     <>
//       {/* Toast Container for notifications */}
//       <ToastContainer position="top-center" autoClose={3000} />

//       <div className="container-fluid">
//         <div className="row justify-content-center">
//           <div className="col-lg-5 p-0 d-none d-lg-block">
//             <div
//               className="w-100 h-100"
//               style={{
//                 backgroundImage: `url(${LeftImage})`,
//                 backgroundSize: "cover", // Ensures the image covers the entire area
//                 backgroundPosition: "center", // Centers the image
//                 backgroundRepeat: "no-repeat", // Prevents tiling
//                 objectFit: "cover",
//                 filter: "blur(1px)",
//               }}
//             >
//               <div
//                 className="ms-3"
//                 style={{ cursor: "pointer", color: "#FFFFFF" }}
//                 onClick={() => {
//                   window.location.href = "/";
//                 }}
//               >
//                 <h3>
//                   <b>trxvllrschoice</b>
//                 </h3>
//               </div>
//               <div className="h-75 d-flex justify-content-center align-items-center">
//                 <h4 style={{ color: "#FFFFFF" }}>
//                   <b>LADAKH</b>
//                 </h4>
//               </div>
//             </div>
//           </div>
//           <div
//             className="col-lg-7 "
//             style={{ backgroundColor: "#03045E", height: "100vh" }}
//           >
//             <div className="h-100 d-flex align-items-center justify-content-center">
//               <div
//                 className="card mx-2 custom-card "
//                 style={{ maxHeight: "550px", borderRadius: "20px" }}
//               >
//                 <div className="card-body p-5">
//                   <h2 className="card-title pt-3">OTP Verification</h2>
//                   <p className="text-muted pb-3" style={{ fontSize: "medium" }}>
//                     Please Verify Your OTP Send On Your Device
//                   </p>
//                   {loading && <Spinner />} {/* Render spinner if loading */}
//                   <form onSubmit={handleSubmit}>
//                     <div className="mb-3 d-flex justify-content-between">
//                       {otp.map((digit, index) => (
//                         <input
//                           key={index}
//                           type="text"
//                           value={digit}
//                           maxLength="1"
//                           onChange={(e) =>
//                             handleOtpChange(index, e.target.value)
//                           }
//                           className="form-control"
//                           style={{
//                             width: "calc(20% - 10px)",
//                             textAlign: "center",
//                             marginRight: "10px",
//                             boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
//                           }}
//                           ref={(input) => (inputRefs.current[index] = input)} // Ref for focusing
//                           required
//                         />
//                       ))}
//                     </div>
//                     <p className="text-center mb-3">
//                       {Math.floor(timer / 60)}:
//                       {timer % 60 < 10 ? "0" + timer % 60 : timer % 60}
//                     </p>
//                     <p
//                       className="text-center mb-3"
//                       style={{ color: "#03045E" }}
//                     >
//                       Didn't Receive Code? <b>Resend</b>
//                     </p>
//                     <div className="text-center">
//                       <button
//                         type="submit"
//                         className="btn mt-4"
//                         style={{
//                           width: "200px",
//                           borderRadius: "20px",
//                           backgroundColor: "#03045E",
//                           color: "white",
//                           fontSize: "1.2rem",
//                         }}
//                       >
//                         Submit
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default ForgetOtp;

import React, { useState, useEffect, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LeftImage from "../../../assets/Images/a1a1.png";
import { useNavigate } from "react-router-dom";

// Define the spinner component
const Spinner = () => (
  <div className="loader-container">
    <div className="loader"></div>
  </div>
);

function ForgetOtp({ props }) {
  const [otp, setOtp] = useState(["", "", "", ""]); // Array to store OTP digits
  const [timer, setTimer] = useState(120); // Timer in seconds
  const [disabled, setDisabled] = useState(false); // Button disabled state
  const [loading, setLoading] = useState(false); // State for loading spinner
  const inputRefs = useRef([]);
  const navigate = useNavigate(); // Get the navigate function from react-router-dom

  useEffect(() => {
    // Start the timer
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    // Clear the timer when it reaches 0
    if (timer === 0) {
      clearInterval(interval);
      setDisabled(true); // Disable button when timer reaches 0
    }

    // Clean up function
    return () => clearInterval(interval);
  }, [timer]);

  // Function to handle OTP input
  const handleOtpChange = (index, value) => {
    // Check if the entered value is a number
    if (/^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move focus to the next input field
      if (index < 3 && value !== "") {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  // Handle Backspace Key (navigate to previous input if current input is empty)
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      // Focus on the previous input field when backspace is pressed on an empty field
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  // Function to handle form submission
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true); // Set loading state to true when submitting the form

  //   const otpCode = otp.join(""); // Join OTP digits into a single string
  //   console.log("OTP submitted:", otpCode);

  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_URL}forgotPasswordVerify`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({ otp: otpCode }), // Send OTP to the backend
  //       }
        
  //     );
  //     console.log(await response.json());
  //     // Check if the response is okay
  //     if (response.ok) {
  //       toast.success("OTP verified successfully!");
  //       navigate("/ConfirmPassword");
  //     } else {
  //       const errorData = await response.json();
  //       toast.error(errorData.message || "OTP verification failed!");
  //     }
  //   } catch (error) {
  //     toast.error("Error verifying OTP. Please try again.");
  //     console.error("Error verifying OTP:", error);
  //   } finally {
  //     setLoading(false); // Set loading state to false after submission
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true when submitting the form
  
    const otpCode = otp.join(""); // Join OTP digits into a single string
    console.log("OTP submitted:", otpCode);
    const resetToken = sessionStorage.getItem("resetToken");
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}forgotPasswordVerify`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token: resetToken ,otp: otpCode }), // Send OTP to the backend
        }
      );
  
      const responseData = await response.json(); // Parse the response body as JSON
      console.log("->",responseData);
      if (response.ok && responseData.status === "true") {
        // Navigate only if response.ok and status is 'true'
        toast.success("OTP verified successfully!");
        navigate("/ConfirmPassword");
      } else {
        console.log(responseData)
        // Handle invalid OTP or other issues
        toast.error(responseData.message || "OTP verification failed!");
      }
    } catch (error) {
      toast.error("Error verifying OTP. Please try again.");
      console.error("Error verifying OTP:", error);
    } finally {
      setOtp(["", "", "", ""]);
      setLoading(false); // Set loading state to false after submission
    }
  };
  

  return (
    <>
      {/* Toast Container for notifications */}
      <ToastContainer position="top-center" autoClose={3000} />

      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-lg-5 p-0 d-none d-lg-block">
            <div
              className="w-100 h-100"
              style={{
                backgroundImage: `url(${LeftImage})`,
                backgroundSize: "cover", // Ensures the image covers the entire area
                backgroundPosition: "center", // Centers the image
                backgroundRepeat: "no-repeat", // Prevents tiling
                objectFit: "cover",
                filter: "blur(1px)",
              }}
            >
              <div
                className="ms-3"
                style={{ cursor: "pointer", color: "#FFFFFF" }}
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                <h3>
                  <b>trxvllrschoice</b>
                </h3>
              </div>
              <div className="h-75 d-flex justify-content-center align-items-center">
                <h4 style={{ color: "#FFFFFF" }}>
                  <b>LADAKH</b>
                </h4>
              </div>
            </div>
          </div>
          <div
            className="col-lg-7 "
            style={{ backgroundColor: "#03045E", height: "100vh" }}
          >
            <div className="h-100 d-flex align-items-center justify-content-center">
              <div
                className="card mx-2 custom-card "
                style={{ maxHeight: "550px", borderRadius: "20px" }}
              >
                <div className="card-body p-5">
                  <h2 className="card-title pt-3">OTP Verification</h2>
                  <p className="text-muted pb-3" style={{ fontSize: "medium" }}>
                    Please Verify Your OTP Sent On Your Device
                  </p>
                  {loading && <Spinner />} {/* Render spinner if loading */}
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3 d-flex justify-content-between">
                      {otp.map((digit, index) => (
                        <input
                          key={index}
                          type="text"
                          value={digit}
                          maxLength="1"
                          onChange={(e) =>
                            handleOtpChange(index, e.target.value)
                          }
                          onKeyDown={(e) => handleKeyDown(e, index)} // Handle backspace
                          className="form-control"
                          style={{
                            width: "calc(20% - 10px)",
                            textAlign: "center",
                            marginRight: "10px",
                            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                          }}
                          ref={(input) => (inputRefs.current[index] = input)} // Ref for focusing
                          required
                        />
                      ))}
                    </div>
                    <p className="text-center mb-3">
                      {Math.floor(timer / 60)}:
                      {timer % 60 < 10 ? "0" + timer % 60 : timer % 60}
                    </p>
                    <p
                      className="text-center mb-3"
                      style={{ color: "#03045E" }}
                    >
                      Didn't Receive Code? <b>Resend</b>
                    </p>
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn mt-4"
                        style={{
                          width: "200px",
                          borderRadius: "20px",
                          backgroundColor: "#03045E",
                          color: "white",
                          fontSize: "1.2rem",
                        }}
                        disabled={disabled} // Disable button if time is up
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgetOtp;
