// import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Link } from "react-router-dom";
// import {
//   faFacebook,
//   faInstagram,
//   faTwitter,
//   faLinkedin,
//   faYoutube,
// } from "@fortawesome/free-brands-svg-icons";
// import logo from "../../assets/Images/White_TCL_logo.png";
// import "./Footer.css";
// const Footer = () => {
//   return (
//     <div
//       className="container-fluid px-md-5 px-2"
//       style={{ backgroundColor: "#111E43", color: "#FFFFFF" }}
//     >
//       <div className="row p-sm-5 p-0">
//         <div className="col-lg-5 pt-4 pe-4">
//           {" "}
//           {/* Add mr-lg-5 class for margin right */}
//           <h3 className="mb-4 d-flex align-items-center">
//             <img src={logo} alt="Logo" style={{ marginRight: "10px" }} width="185" />

//           </h3>
//           <p className="mb-4 fs-14">
//             Join our newsletter to stay up to date on features and releases.
//           </p>
//           <div className="input-group mb-4">
//             <input
//               type="text"
//               className="form-control white-placeholder"
//               placeholder="Enter Your Email"
//               aria-label="Recipient's email"
//               aria-describedby="basic-addon2"
//               style={{
//                 backgroundColor: "#111E43",
//                 color: "#FFFFFF",
//                 borderColor: "#FFFFFF",
//                 borderRadius: "5px",
//               }}
//             />
//             <button
//               className="btn btn-primary fw-bold"
//               type="button"
//               style={{
//                 marginLeft: "15px",
//                 backgroundColor: "#111E43",
//                 color: "#FFFFFF",
//                 borderColor: "#FFFFFF",
//                 borderRadius: "5px",
//               }}
//             >
//               Subscribe
//             </button>
//           </div>
//           <p className="fs-14">
//             By subscribing you agree to our Privacy Policy and provide consent
//             to receive updates from our company.
//           </p>
//         </div>
//         <div className="col-lg-7 pt-4">
//           <div className="row">
//             <div className="col-12 col-md-4">
//               <h5 className="fw-bold">Customer Resource</h5>
//               <ul
//                 className="mt-4 fs-14"
//                 style={{ listStyleType: "none", paddingLeft: "0" }}
//               >
//                 <li className="mb-4">
//                   <Link
//                     to="/helpMe"
//                     style={{ color: "#FFFFFF", textDecoration: "none" }}
//                   >
//                     Help me plan my trip
//                   </Link>
//                 </li>
//                 <li className="mb-4">
//                   <Link
//                     to="/blogs"
//                     style={{ color: "#FFFFFF", textDecoration: "none" }}
//                   >
//                     Blogs
//                   </Link>
//                 </li>
//                 <li className="mb-4">
//                   <Link
//                     to="/ContactUs"
//                     style={{ color: "#FFFFFF", textDecoration: "none" }}
//                   >
//                     Contact Us
//                   </Link>
//                 </li>
//                 <li className="mb-4">
//                   <Link
//                     to="/faq"
//                     style={{ color: "#FFFFFF", textDecoration: "none" }}
//                   >
//                     FAQ
//                   </Link>
//                 </li>
//               </ul>
//             </div>
//             <div className="col-12 col-md-4">
//               <h5 className="fw-bold">Destination Resource</h5>
//               <ul
//                 className="mt-4 fs-14"
//                 style={{ listStyleType: "none", paddingLeft: "0" }}
//               >
//                 <li className="mb-4">
//                   <Link to="/LadakhDest" className="link-style">
//                     Nubra Valley
//                   </Link>
//                 </li>
//                 <li className="mb-4">
//                   <Link to="/LadakhDest" className="link-style">
//                     Leh
//                   </Link>
//                 </li>
//                 <li className="mb-4">
//                   <Link to="/LadakhDest" className="link-style">
//                   Leh Palace
//                   </Link>
//                 </li>
//                 <li className="mb-4">
//                   <Link to="/LadakhDest" className="link-style">
//                   Magnetic Hill
//                   </Link>
//                 </li>

//               </ul>
//             </div>
//             <div className="col-12 col-md-4">
//               <h5 className="fw-bold">Follow Us</h5>
//               <ul
//                 className="mt-4 fs-14"
//                 style={{ listStyleType: "none", paddingLeft: "0" }}>

//                 <li className="mb-4">
//                   <Link to="https://www.facebook.com/profile.php?id=100088895292951&mibextid=ZbWKwL" className="text-white" style={{ textDecoration: 'none' }}>
//                     <FontAwesomeIcon
//                       icon={faFacebook}
//                       style={{ color: "#ffffff",listStyle:'none' }}
//                     />{" "}
//                     Facebook
//                   </Link>
//                 </li>
//                 <li className="mb-4">
//                   <Link to="https://www.instagram.com/travellers_choice_ladakh/" className="text-white" style={{ textDecoration: 'none' }}>
//                     <FontAwesomeIcon
//                       icon={faInstagram}
//                       style={{ color: "#ffffff" }}
//                     />{" "}
//                     Instagram
//                   </Link>
//                 </li>
//                 <li className="mb-4">
//                   <Link to="https://youtube.com/@TRAVELLERSCHOICE-ll2kl?si=EX6n-jYw7HA-1oK3" className="text-white" style={{ textDecoration: 'none' }}>
//                     <FontAwesomeIcon
//                       icon={faYoutube}
//                       style={{ color: "#ffffff" }}
//                     />{" "}
//                     YouTube
//                   </Link>
//                 </li>
//                 <li className="mb-4">
//                   <Link to="/linkedin" className="text-white" style={{ textDecoration: 'none' }}>
//                     <FontAwesomeIcon
//                       icon={faLinkedin}
//                       style={{ color: "#ffffff" }}
//                     />{" "}
//                     LinkedIn
//                   </Link>
//                 </li>

//               </ul>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.5)" }}></div>

//       <div className="row p-sm-5 p-0">
//         <div className="col-lg-8 ps-4 fs-14">
//           <p>
//             Copyright © travellerchoiceladakh.com | Designed & Developed By
//             nomADZ Digital & Innovation
//           </p>
//         </div>

//         <div className="col-lg-4 d-flex justify-content-between fs-14 ">
//           <ul className="flex-wrap" style={{ listStyleType: "none", display: "flex", gap: "30px" }}>
//             <li className="mb-4">
//               <Link
//                 to="/CancellationPolicy"
//                 style={{
//                   color: "#FFFFFF",
//                   textDecoration: "none",
//                   listStyleType: "none",
//                 }}
//               >
//                 Cancellation Policy
//               </Link>
//             </li>
//             <li className="mb-4">
//               <Link
//                 to="/privacyPolicy"
//                 style={{
//                   color: "#FFFFFF",
//                   textDecoration: "none",
//                   listStyleType: "none",
//                 }}
//               >
//                 Privacy Policy
//               </Link>
//             </li>
//             <li className="mb-4">
//               <Link
//                 to="/Terms"
//                 style={{
//                   color: "#FFFFFF",
//                   textDecoration: "none",
//                   listStyleType: "none",
//                 }}
//               >
//                 Terms of Use
//               </Link>
//             </li>
//           </ul>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Footer;

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import logo from "../../assets/Images/White_TCL_logo.png";
import "./Footer.css";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubscribe = async () => {
    if (!validateEmail(email)) {
      setPopupMessage("Please enter a valid email address.");
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 3000);
      return;
    }

    try {
      const response = await fetch("https://api.travellerchoiceladakh.com/api/SubscriberFooter", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      // const data = await response.json(); // Assuming the response is JSON
      // console.log(data); 
      if (response.ok) {
        setPopupMessage("Subscribed successfully!");
        setEmail(""); // Clear the input field
      } else {
        setPopupMessage("Subscription failed. Please try again.");
      }
    } catch (error) {
      setPopupMessage("An error occurred. Please try again.");
    } finally {
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 3000); // Hide the pop-up after 3 seconds
    }
  };

  return (
    <div
      className="container-fluid px-md-5 px-2"
      style={{ backgroundColor: "#111E43", color: "#FFFFFF" }}
    >
      {showPopup && (
        <div className="popup">
          {popupMessage}
        </div>
      )}
      <div className="row p-sm-5 p-0">
        <div className="col-lg-5 pt-4 pe-4">
          <h3 className="mb-4 d-flex align-items-center">
            <img src={logo} alt="Logo" style={{ marginRight: "10px" }} width="185" />
          </h3>
          <p className="mb-4 fs-14">
            Join our newsletter to stay up to date on features and releases.
          </p>
          <div className="input-group mb-4">
            <input
              type="text"
              className="form-control white-placeholder"
              placeholder="Enter Your Email"
              aria-label="Recipient's email"
              aria-describedby="basic-addon2"
              style={{
                backgroundColor: "#111E43",
                color: "#FFFFFF",
                borderColor: "#FFFFFF",
                borderRadius: "5px",
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              className="btn btn-primary fw-bold"
              type="button"
              style={{
                marginLeft: "15px",
                backgroundColor: "#111E43",
                color: "#FFFFFF",
                borderColor: "#FFFFFF",
                borderRadius: "5px",
              }}
              onClick={handleSubscribe}
            >
              Subscribe
            </button>
          </div>
          <p className="fs-14">
            By subscribing you agree to our Privacy Policy and provide consent
            to receive updates from our company.
          </p>
        </div>
        <div className="col-lg-7 pt-4">
          <div className="row">
            <div className="col-12 col-md-4">
              <h5 className="fw-bold">Customer Resource</h5>
              <ul
                className="mt-4 fs-14"
                style={{ listStyleType: "none", paddingLeft: "0" }}
              >
                <li className="mb-4">
                  <Link
                    to="/plan-ladakh-trip"
                    style={{ color: "#FFFFFF", textDecoration: "none" }}
                  >
                    Help me plan my trip
                  </Link>
                </li>

                {/* <li className="mb-4">
                  <Link
                    to="/blogs"
                    style={{ color: "#FFFFFF", textDecoration: "none" }}
                  >
                    Blogs
                  </Link>
                </li> */}

                <li className="mb-4">
                  <button
                    style={{ color: "#FFFFFF", textDecoration: "none", background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                    onClick={() => {
                      window.location.href = "https://travellerchoiceladakh.com/blog/";
                    }}
                  >
                    Blogs
                  </button>
                </li>


                <li className="mb-4">
                  <Link
                    to="/contact-us"
                    style={{ color: "#FFFFFF", textDecoration: "none" }}
                  >
                    Contact Us
                  </Link>
                </li>
                {/* <li className="mb-4">
                  <Link
                    to="/faq"
                    style={{ color: "#FFFFFF", textDecoration: "none" }}
                  >
                    FAQ
                  </Link>
                </li> */}
              </ul>
            </div>
            <div className="col-12 col-md-4">
              <h5 className="fw-bold">Destination Resource</h5>
              <ul
                className="mt-4 fs-14"
                style={{ listStyleType: "none", paddingLeft: "0" }}
              >
                <li className="mb-4">
                  <Link to="/ladakh-dest" className="link-style">
                    Nubra Valley
                  </Link>
                </li>
                <li className="mb-4">
                  <Link to="/ladakh-dest" className="link-style">
                    Leh
                  </Link>
                </li>
                <li className="mb-4">
                  <Link to="/ladakh-dest" className="link-style">
                    Leh Palace
                  </Link>
                </li>
                <li className="mb-4">
                  <Link to="/ladakh-dest" className="link-style">
                    Magnetic Hill
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-4">
              <h5 className="fw-bold">Follow Us</h5>
              <ul
                className="mt-4 fs-14"
                style={{ listStyleType: "none", paddingLeft: "0" }}
              >
                <li className="mb-4">
                  <Link to="https://www.facebook.com/profile.php?id=100088895292951&mibextid=ZbWKwL" className="text-white" style={{ textDecoration: 'none' }}>
                    <FontAwesomeIcon
                      icon={faFacebook}
                      style={{ color: "#ffffff", listStyle: 'none' }}
                    />{" "}
                    Facebook
                  </Link>
                </li>
                <li className="mb-4">
                  <Link to="https://www.instagram.com/travellers_choice_ladakh/" className="text-white" style={{ textDecoration: 'none' }}>
                    <FontAwesomeIcon
                      icon={faInstagram}
                      style={{ color: "#ffffff" }}
                    />{" "}
                    Instagram
                  </Link>
                </li>
                <li className="mb-4">
                  <Link to="https://youtube.com/@TRAVELLERSCHOICE-ll2kl?si=EX6n-jYw7HA-1oK3" className="text-white" style={{ textDecoration: 'none' }}>
                    <FontAwesomeIcon
                      icon={faYoutube}
                      style={{ color: "#ffffff" }}
                    />{" "}
                    YouTube
                  </Link>
                </li>
                {/* <li className="mb-4">
                  <Link to="/linkedin" className="text-white" style={{ textDecoration: 'none' }}>
                    <FontAwesomeIcon
                      icon={faLinkedin}
                      style={{ color: "#ffffff" }}
                    />{" "}
                    LinkedIn
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.5)" }}></div>
      <div className="row p-sm-5 p-0">
        <div className="col-lg-8 ps-4 fs-14">
          {/* <p>
            Copyright © travellerchoiceladakh.com | Designed & Developed By
            Nomadz Digital & Innovation
          </p> */}
          <p>
  Copyright © travellerchoiceladakh.com | Designed & Developed By
  <a href="https://www.nomadzdigital.com/" target="_blank" rel="noopener noreferrer" style={{textDecoration: "none",marginLeft:"5px",color:"white"}}>
    Nomadz Digital & Innovation
  </a>
</p>
        </div>
        <div className="col-lg-4 d-flex justify-content-between fs-14 ">
          <ul className="flex-wrap" style={{ listStyleType: "none", display: "flex", gap: "30px" }}>
            <li className="mb-4">
              <Link
                to="/cancellation-policy"
                style={{
                  color: "#FFFFFF",
                  textDecoration: "none",
                  listStyleType: "none",
                }}
              >
                Cancellation Policy
              </Link>
            </li>
            <li className="mb-4">
              <Link
                to="/privacy-policy"
                style={{
                  color: "#FFFFFF",
                  textDecoration: "none",
                  listStyleType: "none",
                }}
              >
                Privacy Policy
              </Link>
            </li>
            <li className="mb-4">
              <Link
                to="/terms-and-condition"
                style={{
                  color: "#FFFFFF",
                  textDecoration: "none",
                  listStyleType: "none",
                }}
              >
                Terms of Use
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
