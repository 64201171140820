// import React, { useEffect } from "react";
// import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
// import SignUp from "./component/userAuth/SignUp";
// import Otp from "./component/userAuth/Otp";
// import LogIn from "./component/userAuth/LogIn";
// import ForgetPassword from "./component/userAuth/ForgetPasswrd/ForgetPassword";
// import ForgetOtp from "./component/userAuth/ForgetPasswrd/ForgetOtp";
// import ConfirmPassword from "./component/userAuth/ForgetPasswrd/ConfirmPassword";
// import Home from "./component/HomePage/Home";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './App.css';
// import './index.css';
// import ProfilePage1 from "./component/Profile/ProfilePage1";
// import Bookings from "./component/Profile/Bookings";
// import Quotations from "./component/Profile/Quotations";
// import Transactions from "./component/Profile/Transactions";
// import Blogs from "./component/Blog/Blogs";
// import HelpMePlan from "./component/HomePage/HelpMePlan";
// import LadakhDestinations from "./component/HomePage/LadakhDestinations";
// import TripPackage from "./component/HomePage/TripPackage";
// import PackageDestination from "./component/HomePage/PackageDestination";
// import BlogInner from "./component/Blog/BlogInner";
// import Faq from "./component/FooterComponents/Faq";
// import ContactUs from "./component/Contact/ContactUs";
// import CancellationPolicy from "./component/Policies/CancellationPolicy";
// import PrivacyPolicy from "./component/Policies/PrivacyPolicy";
// import TermsNConditions from "./component/Policies/TermsNConditions";
// import AllPackages from "./component/AllPackages/AllPackages";
// import BookOnline from "./component/Booking/BookOnline";
// import Thankyou from "./component/ThankYouPAage/Thankyou";
// import BookOnlineCarHotel from "./component/Booking/BookOnlineCarHotel";
// import HotelCard from "./component/HomePage/HotelCard";

// function ScrollToTopOnMount() {
//   const { pathname } = useLocation();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   return null;
// }

// function App() {
//   return (
//     <>
//      <BrowserRouter>
//        <ScrollToTopOnMount />
//        <Routes>
//          <Route path="/" element={<Home/>}/>
//          <Route path="/Otp" element={<Otp/>}/>
//          <Route path="/LogIn" element={<LogIn/>}/>
//          <Route path="/ForgetPassword" element={<ForgetPassword/>}/>
//          <Route path="/ForgetOtp" element={<ForgetOtp/>}/>
//          <Route path="/ConfirmPassword" element={<ConfirmPassword/>}/>
//          <Route path="/signup" element={<SignUp/>}/>
//          <Route path="/profilePage1" element={<ProfilePage1/>}/>
//          <Route path="/booking" element={<Bookings/>}/>
//          <Route path="/quotation" element={<Quotations/>}/>
//          <Route path="/transaction" element={<Transactions/>}/>
//          <Route path="/blogs" element={<Blogs/>}/>
//          <Route path="/plan-ladakh-trip" element={<HelpMePlan/>}/>
//          <Route path="/LadakhDest" element={<LadakhDestinations/>}/>
//          <Route path="/PackageDestination/:slug" element={<PackageDestination/>}/>
//          <Route path="/TripPackages/:Name" element={<TripPackage />} />
//          <Route path="/BlogInner/:Id" element={<BlogInner />} />
//          <Route path="/Faq" element={<Faq/>} />
//          <Route path="/ContactUs" element={<ContactUs/>} />
//          <Route path="/CancellationPolicy" element={<CancellationPolicy/>} />
//          <Route path="/PrivacyPolicy" element={<PrivacyPolicy/>} />
//          <Route path="/Terms" element={<TermsNConditions/>} />
//          <Route path="/AllPackages" element={<AllPackages/>} />
//          <Route path="/BookOnline" element={<BookOnline/>} />
//          <Route path="/Thankyou" element={<Thankyou/>} />
//          <Route path="/BookOnlineCarHotel" element={<BookOnlineCarHotel/>} />
//          <Route path="/HotelCard"  element={<HotelCard/>} />
//        </Routes>
//      </BrowserRouter>
//     </>
//   );
// }
// export default App;

import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import SignUp from "./component/userAuth/SignUp";
import Otp from "./component/userAuth/Otp";
import LogIn from "./component/userAuth/LogIn";
import ForgetPassword from "./component/userAuth/ForgetPasswrd/ForgetPassword";
import ForgetOtp from "./component/userAuth/ForgetPasswrd/ForgetOtp";
import ConfirmPassword from "./component/userAuth/ForgetPasswrd/ConfirmPassword";
import Home from "./component/HomePage/Home";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './index.css';
import ProfilePage1 from "./component/Profile/ProfilePage1";
import Bookings from "./component/Profile/Bookings";
import Quotations from "./component/Profile/Quotations";
import Transactions from "./component/Profile/Transactions";
import Blogs from "./component/Blog/Blogs";
import HelpMePlan from "./component/HomePage/HelpMePlan";
import LadakhDestinations from "./component/HomePage/LadakhDestinations";
import TripPackage from "./component/HomePage/TripPackage";
import PackageDestination from "./component/HomePage/PackageDestination";
import BlogInner from "./component/Blog/BlogInner";
import Faq from "./component/FooterComponents/Faq";
import ContactUs from "./component/Contact/ContactUs";
import CancellationPolicy from "./component/Policies/CancellationPolicy";
import PrivacyPolicy from "./component/Policies/PrivacyPolicy";
import TermsNConditions from "./component/Policies/TermsNConditions";
import AllPackages from "./component/AllPackages/AllPackages";
import BookOnline from "./component/Booking/BookOnline";
import Thankyou from "./component/ThankYouPAage/Thankyou";
import BookOnlineCarHotel from "./component/Booking/BookOnlineCarHotel";
import HotelCard from "./component/HomePage/HotelCard";


function ScrollToTopOnMount() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTopOnMount />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Otp" element={<Otp />} />
          <Route path="/login" element={<LogIn />} />
          <Route path="/ForgetPassword" element={<ForgetPassword />} />
          <Route path="/ForgetOtp" element={<ForgetOtp />} />
          <Route path="/ConfirmPassword" element={<ConfirmPassword />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/profilePage1" element={<ProfilePage1 />} />
          <Route path="/booking" element={<Bookings />} />
          <Route path="/quotation" element={<Quotations />} />
          <Route path="/transaction" element={<Transactions />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/plan-ladakh-trip" element={<HelpMePlan />} />
          <Route path="/ladakh-dest" element={<LadakhDestinations />} />
          {/* <Route path="/PackageDestination/:slug" element={<PackageDestination />} /> */}
          <Route path="/:slug" element={<PackageDestination />} />
          {/* <Route path="/TripPackages/:Name" element={<TripPackage />} /> */}
          <Route path="/TripPackages/:name" element={<TripPackage />} />
          <Route path="/BlogInner/:Id" element={<BlogInner />} />
          <Route path="/Faq" element={<Faq />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/cancellation-policy" element={<CancellationPolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-condition" element={<TermsNConditions />} />
          {/* <Route path="/AllPackages" element={<AllPackages />} /> */}
          <Route path="/all-packages" element={<AllPackages />} />
          <Route path="/BookOnline" element={<BookOnline />} />
          <Route path="/Thankyou" element={<Thankyou />} />
          <Route path="/BookOnlineCarHotel" element={<BookOnlineCarHotel />} />
          <Route path="/Ladakh-Stay" element={<HotelCard />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

